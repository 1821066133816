<template>
  <svg version="1.1" id="logo" xmlns:x="&amp;ns_extend;" xmlns:i="&amp;ns_ai;" xmlns:graph="&amp;ns_graphs;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/" x="0px" y="0px" viewBox="-73.2 121.9 713.3 361.3" enable-background="new -73.2 121.9 713.3 361.3" xml:space="preserve" data-llp-composed="true" class="lazy-line-painter">
    <path fill="#FFFFFF" d="M336.6,194.7c-2.2-2.2-3.6-3.4-5.5-4.1c-2.2-0.7-5-1-10.1-1c-3.1,0-6.5-0.7-9.3-1.9s-5.5-3.1-7.9-5.3
      c-5-5-7.4-11.3-7.4-17.7c0-6.5,2.4-12.9,7.4-17.7c5-4.8,11.5-7.7,18-7.7c6.5,0,12.9,2.4,17.7,7.4c2.4,2.4,4.1,5,5.3,7.9
      c1.2,2.9,1.9,6.2,1.9,9.3c0,5,0.2,7.9,1,10.1c0.7,1.9,1.9,3.4,3.8,5.5c0.5,0.5,1.2,1.2,1.7,1.7c2.9,2.9,6,5.8,8.9,8.9
      c2.4,2.4,3.8,3.8,6,4.6c2.2,0.7,5,1,10.3,1.2c3.1,0,6.5,0.7,9.3,1.9c2.9,1.2,5.5,3.1,7.9,5.3c5,5,7.4,11.3,7.4,17.7
      s-2.4,12.9-7.4,17.7c-5,5-11.3,7.4-17.7,7.4l0,0c-1.4,0-3.1-0.2-4.6-0.5H373c-4.3-1-14.4-0.2-27.1,2.6c-10.8,2.2-23.3,5.8-35.5,10.8
      c-1.2,0.5-2.6,1-4.3,1.7c-16.3,6.2-52,20.1-52.5,36.2c0,0.7,0,1.7-0.2,2.4c0,0.2,0,0.2,0,0.5c0,2.6,0.5,5,1.4,7.2
      c1.7,4.3,5.3,8.2,12,11.5c8.9,4.3,19.7,8.9,29.2,11.5c7.7,2.2,14.4,2.9,18.5,1.9c3.1-1,6.2-1.2,9.6-0.7c3.1,0.2,6.2,1.2,9.1,2.9
      c6.2,3.4,10.3,8.9,12.2,14.9c1.9,6.2,1.4,12.9-1.9,19.2c-3.4,6.2-8.9,10.3-14.9,12.2c-6.2,1.9-12.9,1.4-19.2-1.9
      c-2.9-1.7-5.5-3.6-7.4-6c-2.2-2.4-3.6-5.3-4.6-8.4c-1.7-5.3-6.2-11.3-12-17c-7-6.7-15.6-13.2-24-18.5c-1.9-1.2-3.8-1.9-6-2.6
      c-2.2-0.7-4.3-1-6.5-1c-4.3-0.2-8.2,0.7-12,1.9c-1.9,0.7-4.1,1.4-6,2.4c-6.7,3.8-14.1,6-22.3,6c-12.5,0-23.7-5-31.9-13.2
      s-13.4-19.7-13.4-32.1c0-12.5,5-23.7,13.2-31.9c8.2-8.2,19.4-13.2,31.9-13.2c10.8,0,20.9,3.8,28.5,10.3c1.7,1.2,3.4,2.2,5.3,3.1
      c1.9,1,3.8,1.4,6.2,1.9c13.7,2.4,33.6-1.4,52.5-8.4c19.9-7.4,38.6-18,47.2-28.3c1.4-1.7,2.6-3.8,3.4-6.2c0.5-2.2,0.7-4.3,0.5-6.2
      c-0.7-1.7-1.7-2.9-3.4-4.6c-1.7-1.7-3.4-3.4-5-5C341.1,199.2,339,197.1,336.6,194.7L336.6,194.7z M333.7,365.1
      c0.5-0.5,1.2-0.5,1.4,0c0.5,0.5,0.5,1.2,0,1.4l0,0c-3.1,3.4-7.4,5.3-11.7,5.5c-4.3,0.2-8.9-1.2-12.2-4.3l0,0l0,0
      c-0.5-0.5-0.5-1.2,0-1.4c0.5-0.5,1.2-0.5,1.4,0c3.1,2.6,7,4.1,10.8,3.8C327,369.9,330.8,368.2,333.7,365.1
      C333.7,365.4,333.7,365.4,333.7,365.1L333.7,365.1z M388.1,209.5c-0.5-0.5-0.5-1.2,0-1.4c0.5-0.5,1.2-0.5,1.4,0c3.4,3.4,5,7.7,5,12
      c0,4.3-1.7,8.6-5,12c-0.5,0.5-1.2,0.5-1.4,0c-0.5-0.5-0.5-1.2,0-1.4c2.9-2.9,4.3-6.7,4.3-10.5S391.2,212.4,388.1,209.5L388.1,209.5z
      M178.4,287.4c0,0.5-0.5,1-1,1s-1-0.5-1-1c0-8.6,3.6-16.5,9.3-22.3c5.8-5.8,13.7-9.3,22.3-9.3c0.5,0,1,0.5,1,1c0,0.5-0.5,1-1,1
      c-8.2,0-15.6,3.4-20.9,8.6C181.7,272.1,178.4,279.3,178.4,287.4z M251,305.7c-0.2,0-0.2-0.2-0.5-0.2c-2.2,6.2-5.8,11.7-10.3,16.3
      c-0.2,0.2-0.2,0.2-0.5,0.5c2.9-0.7,6-1.2,9.3-1c2.4,0,5,0.5,7.4,1.2c2.4,0.7,4.6,1.7,6.7,2.9c8.6,5.3,17.7,12,24.7,18.9
      c6.2,6.2,11,12.5,12.9,18.5c1,2.6,2.2,5,4.1,7.2c1.7,2.2,3.8,3.8,6.5,5.3c5.3,2.9,11.3,3.4,16.5,1.7s10.1-5.3,12.9-10.5
      c2.9-5.3,3.4-11.3,1.7-16.5s-5.3-10.1-10.5-12.9c-2.6-1.4-5.3-2.2-7.9-2.4c-2.9-0.2-5.5,0-8.2,0.7c-4.8,1.4-12.2,0.5-20.4-1.9
      c-9.8-2.6-20.9-7.2-29.7-11.7c-7.7-3.8-11.7-8.2-13.7-13.2C251.5,307.3,251.2,306.4,251,305.7L251,305.7z M223.9,328.4
      c1.7-1,3.6-1.7,5.3-2.6c3.1-1.9,6-4.1,8.6-6.7c7.4-7.4,12.2-18,12.2-29.5c0-11.5-4.6-21.8-12.2-29.5c-7.4-7.4-18-12.2-29.5-12.2
      s-21.8,4.6-29.5,12.2c-7.4,7.9-12,18.2-12,29.7c0,11.5,4.6,21.8,12.2,29.5c7.4,7.4,18,12.2,29.5,12.2
      C213.8,331.6,219.1,330.4,223.9,328.4z M364.4,241.9c-1.4-1-2.9-2.2-4.1-3.4c-2.4-2.4-4.1-5-5.3-7.9c-1.2-2.6-1.7-5.5-1.9-8.4
      c-0.7,1.2-1.4,2.4-2.2,3.4c-8.9,10.8-28,21.8-48.4,29.2c-19.4,7.2-40,11-54.2,8.6c-1.2-0.2-2.4-0.5-3.6-1c5.5,7.2,8.6,15.8,9.1,25.4
      c9.1-14.4,37.4-25.2,51.8-30.7c1.7-0.7,2.9-1.2,4.3-1.7c12.5-5,25.2-8.6,36-10.8C352.6,243.3,359.1,242.4,364.4,241.9z M373.5,242.1
      c0.2,0,0.5,0,0.7,0l0,0c1.2,0.2,2.6,0.2,3.8,0.2l0,0c5.5,0,11.3-2.2,15.3-6.5c4.3-4.3,6.5-9.8,6.5-15.3c0-5.5-2.2-11.3-6.5-15.3
      c-1.9-1.9-4.3-3.6-7-4.6c-2.6-1.2-5.3-1.7-8.2-1.7c-5.8-0.2-8.9-0.5-11.5-1.4s-4.3-2.6-7.2-5.5c-2.9-2.9-5.8-5.8-8.6-8.9
      c-0.5-0.5-1.2-1.2-1.7-1.7c-2.4-2.4-3.8-4.1-4.8-6.7c-0.7-2.4-1-5.5-1.2-11c0-2.6-0.7-5.5-1.7-8.2c-1-2.4-2.6-4.8-4.6-7
      c-4.3-4.3-9.8-6.5-15.3-6.5c-5.5,0-11.3,2.2-15.3,6.5c-4.3,4.3-6.5,9.8-6.5,15.3s2.2,11.3,6.5,15.3c1.9,1.9,4.3,3.6,7,4.6
      c2.6,1.2,5.3,1.7,8.2,1.7c5.3,0.2,8.6,0.2,11,1.2c2.6,1,4.3,2.4,6.7,4.8c2.2,2.2,4.3,4.6,6.7,6.7c1.7,1.7,3.4,3.4,5,5
      c2.2,2.2,3.4,3.6,4.1,6c0,0,0,0,0,0.2c0,0.2,0,0.2,0.2,0.5c0.7,2.4,1,5.5,1,10.5c0,2.6,0.7,5.5,1.7,8.2c1,2.4,2.6,4.8,4.6,7
      C365.6,239.3,369.4,241.2,373.5,242.1L373.5,242.1z" data-llp-id="logo-0" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M-22.1,425.6h3.6l17.7,41.8h-5l-4.3-10.3h-20.3l-4.3,10.3h-5L-22.1,425.6z M-11.9,452.8l-8.3-19.9
      l-8.3,19.9H-11.9z" data-llp-id="logo-1" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M4.5,425.6h4.4v33.9c0,3.3,1,4.7,3.8,4.7c0.7,0,1.5,0,2.2-0.1v3.8c-0.7,0.4-1.8,0.5-2.8,0.5
      c-3,0-7.6-0.7-7.6-8.5V425.6z" data-llp-id="logo-2" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M17.2,452.4c0-9.8,6.5-16.1,15.1-16.1c8.4,0,13.9,6.7,13.9,15.1c0,0,0,1-0.1,1.9H21.7
      c0.2,6.7,4.4,10.9,10.8,10.9c4.4,0,6.8-1.6,9.3-4.2l2.9,2.9c-3.8,4.2-7.9,5.3-12.2,5.3c-9,0-15.2-6.1-15.2-15.5V452.4z M41.5,449.4
      c0-4.9-4.1-8.9-9.2-8.9c-5.5,0-10.1,4-10.4,8.9H41.5z" data-llp-id="logo-3" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M58.8,451.4l-10.6-14.2h4.9l8,10.7l8-10.7H74l-10.5,14.1L75,467.4h-4.9l-9-12.7l-9.1,12.7h-4.9L58.8,451.4z
      " data-llp-id="logo-4" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M92.7,436.3c5.9,0,9.2,2.8,10.7,5.4v-4.5h4.4v23.4c0,2.6,0.6,3.8,2.3,3.8c0.4,0,1.1-0.1,1.6-0.1v3.4
      c-1,0.4-1.9,0.5-2.8,0.5s-2.2-0.1-3.2-0.8c-1-0.7-1.8-2-2-4.3c-1.7,2.7-5.2,5.3-11,5.3c-8.5,0-15.3-6.8-15.3-16
      S84.3,436.3,92.7,436.3z M92.7,464.2c6.5,0,11.3-5.1,11.3-11.9c0-6.8-4.8-11.9-11.3-11.9c-6.2,0-10.9,5.1-10.9,11.9
      C81.9,459.1,86.5,464.2,92.7,464.2z" data-llp-id="logo-5" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M116.5,437.2h4.4v5.1c1.6-3.2,4.9-5.6,9.5-5.6c6.8,0,11,4.6,11,11.4v12.5c0,2.3,0.3,3.2,2.1,3.2
      c0.4,0,0.8-0.1,1.1-0.1v3.7c-0.6,0.2-1.3,0.3-1.9,0.3c-2.3,0-5.8-0.6-5.8-6.7v-11.8c0-5-2.9-8.2-7.7-8.2c-4.9,0-8.4,3.4-8.4,8.5
      v18.1h-4.4V437.2z" data-llp-id="logo-6" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M163.1,436.3c5.9,0,9.2,2.8,10.7,5.4v-16.1h4.4v35c0,2.6,0.6,3.8,2.3,3.8c0.4,0,1.1-0.1,1.6-0.1v3.4
      c-1,0.4-1.9,0.5-2.8,0.5s-2.2-0.1-3.2-0.8c-1-0.7-1.8-2-2-4.3c-1.7,2.7-5.2,5.3-11,5.3c-8.5,0-15.3-6.8-15.3-16
      S154.6,436.3,163.1,436.3z M163.1,464.2c6.5,0,11.3-5.1,11.3-11.9c0-6.8-4.8-11.9-11.3-11.9c-6.2,0-10.9,5.1-10.9,11.9
      C152.2,459.1,156.8,464.2,163.1,464.2z" data-llp-id="logo-7" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M184.9,452.4c0-9.8,6.5-16.1,15.1-16.1c8.4,0,13.9,6.7,13.9,15.1c0,0,0,1-0.1,1.9h-24.5
      c0.2,6.7,4.4,10.9,10.8,10.9c4.4,0,6.8-1.6,9.3-4.2l2.9,2.9c-3.8,4.2-7.9,5.3-12.2,5.3c-9,0-15.2-6.1-15.2-15.5V452.4z
      M209.2,449.4c0-4.9-4.1-8.9-9.2-8.9c-5.5,0-10.1,4-10.4,8.9H209.2z" data-llp-id="logo-8" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M219.3,437.2h4.4v5.1c1.5-3.2,4.7-5.6,9.3-5.6c0.7,0,1.4,0.1,1.9,0.1v4.8c-0.7-0.2-1.7-0.3-3-0.3
      c-4.7,0-8.2,3.4-8.2,8.5v17.6h-4.4V437.2z" data-llp-id="logo-9" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M258.4,425.6h4.7l9.3,31.6l10.3-31.6h3.2l10.3,31.6l9.3-31.6h4.7l-12.2,41.8h-3l-10.7-32.3l-10.7,32.3h-3
      L258.4,425.6z" data-llp-id="logo-10" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M311.1,452.4c0-9.8,6.5-16.1,15.1-16.1c8.4,0,13.9,6.7,13.9,15.1c0,0,0,1-0.1,1.9h-24.5
      c0.2,6.7,4.4,10.9,10.8,10.9c4.4,0,6.8-1.6,9.3-4.2l2.9,2.9c-3.8,4.2-7.9,5.3-12.2,5.3c-9,0-15.2-6.1-15.2-15.5V452.4z
      M335.4,449.4c0-4.9-4.1-8.9-9.2-8.9c-5.5,0-10.1,4-10.4,8.9H335.4z" data-llp-id="logo-11" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M345.4,437.2h4.4v5.1c1.6-3.2,4.9-5.6,9.5-5.6c6.8,0,11,4.6,11,11.4v12.5c0,2.3,0.3,3.2,2.1,3.2
      c0.4,0,0.8-0.1,1.1-0.1v3.7c-0.6,0.2-1.3,0.3-1.9,0.3c-2.3,0-5.8-0.6-5.8-6.7v-11.8c0-5-2.9-8.2-7.7-8.2c-4.9,0-8.4,3.4-8.4,8.5
      v18.1h-4.4V437.2z" data-llp-id="logo-12" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M378.7,437.2h4.4v5.1c1.6-3.2,4.9-5.6,9.5-5.6c6.8,0,11,4.6,11,11.4v12.5c0,2.3,0.3,3.2,2.1,3.2
      c0.4,0,0.8-0.1,1.1-0.1v3.7c-0.6,0.2-1.3,0.3-1.9,0.3c-2.3,0-5.8-0.6-5.8-6.7v-11.8c0-5-2.9-8.2-7.7-8.2c-4.9,0-8.4,3.4-8.4,8.5
      v18.1h-4.4V437.2z" data-llp-id="logo-13" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M410.3,452.4c0-9.8,6.5-16.1,15.1-16.1c8.4,0,13.9,6.7,13.9,15.1c0,0,0,1-0.1,1.9h-24.5
      c0.2,6.7,4.4,10.9,10.8,10.9c4.4,0,6.8-1.6,9.3-4.2l2.9,2.9c-3.8,4.2-7.9,5.3-12.2,5.3c-9,0-15.2-6.1-15.2-15.5V452.4z
      M434.5,449.4c0-4.9-4.1-8.9-9.2-8.9c-5.5,0-10.1,4-10.4,8.9H434.5z" data-llp-id="logo-14" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M444.6,437.2h4.4v5.1c1.5-3.2,4.7-5.6,9.3-5.6c0.7,0,1.4,0.1,1.9,0.1v4.8c-0.7-0.2-1.7-0.3-3-0.3
      c-4.7,0-8.2,3.4-8.2,8.5v17.6h-4.4V437.2z" data-llp-id="logo-15" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M466.2,459.5c0.7,2.3,3.1,4.6,7.6,4.6c5.6,0,6.7-2.9,6.7-4.6c0-3.3-2.8-4.4-7.6-6.3c-4.8-1.9-8.2-4-8.2-8.8
      c0-4.7,3.8-8,9.1-8c4.1,0,7,1.8,8.8,5.2l-3.6,1.9c-1.1-2-2.6-3-5.1-3c-2.7,0-4.7,1.4-4.7,3.9c0,2.7,2.6,4,6.6,5.5
      c5.8,2.2,9.2,4.8,9.2,9.7c0,4.3-3.4,8.8-11.1,8.8c-5.6,0-10.1-2.5-11.4-7.3L466.2,459.5z" data-llp-id="logo-16" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M491.5,441.3H488v-4.1h3.5v-11.6h4.4v11.6h7v4.1h-7V459c0,3.9,1.3,5.2,4.2,5.2c0.8,0,1.7,0,2.8-0.2v3.8
      c-0.8,0.4-2.1,0.5-3.4,0.5c-3.4,0-8.1-1.2-8.1-9.4V441.3z" data-llp-id="logo-17" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M508.1,437.2h4.4v5.1c1.5-3.2,4.7-5.6,9.3-5.6c0.7,0,1.4,0.1,1.9,0.1v4.8c-0.7-0.2-1.7-0.3-3-0.3
      c-4.7,0-8.2,3.4-8.2,8.5v17.6h-4.4V437.2z" data-llp-id="logo-18" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M530.7,464.1c-3.1-2.9-5-7-5-11.8c0-9.2,7.2-16,16.1-16c2.9,0,5.6,0.7,7.9,2l2.2-3.1l3.2,2.3l-2.3,3
      c3.1,2.9,5,7,5,11.8c0,9.2-7.2,16-16.1,16c-2.8,0-5.5-0.7-7.8-1.9l-2.5,3.4l-3.2-2.4L530.7,464.1z M547.2,441.7
      c-1.6-0.8-3.4-1.3-5.4-1.3c-6.7,0-11.6,5.1-11.6,11.9c0,3.3,1.2,6.2,3.2,8.3L547.2,441.7z M541.8,464.2c6.7,0,11.6-5.1,11.6-11.9
      c0-3.2-1.1-6.1-3.1-8.2L536.5,463C538,463.8,539.8,464.2,541.8,464.2z" data-llp-id="logo-19" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
    <path fill="#FFFFFF" d="M563.4,437.2h4.4v5.1c1.4-3.2,4.3-5.6,8.5-5.6c4.3,0,7.4,2.3,8.9,6c1.7-3.7,5-6,9.4-6
      c6.1,0,9.8,4.6,9.8,11.4v12.5c0,2.3,0.4,3.2,2.1,3.2c0.4,0,0.8-0.1,1.1-0.1v3.7c-0.6,0.2-1.3,0.3-1.9,0.3c-2.3,0-5.8-0.6-5.8-6.7
      v-11.8c0-5-2.5-8.2-6.6-8.2c-4.1,0-7.2,3.4-7.2,8.5v18.1h-4.4v-18.4c0-5-2.5-8.2-6.7-8.2c-4.1,0-7.3,3.4-7.3,8.5v18.1h-4.4V437.2z" data-llp-id="logo-20" data-llp-duration="2680" data-llp-delay="0" fill-opacity="0" style=""></path>
  </svg>
</template>

<script>
// import logo from '@/assets/img/logo.svg'
import LazyLinePainter from 'lazy-line-painter'

export default {
  name: "Logo",
  data() {
    return {
      // logo
    };
  },
  mounted() {
    // select your svg
    let el = document.querySelector('#logo')

    // initialise & configure LazyLinePainter
    let myAnimation = new LazyLinePainter(el, {"ease":"easeInCubic","strokeWidth":1,"strokeOpacity":1,"strokeColor":"#ffffff","strokeCap":"square"}); 

    // paint! :)
    myAnimation.paint()
  },
  methods: {
    play() {},
  },
};
</script>
