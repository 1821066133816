<template>
    <div class="landing-nav">
        <div class="landing-nav-content">
            <div class="landing-nav-content-inner">
                <div class="landing-nav-section" v-for="(section, index) in sections" :key="index">
                    <h3 class="landing-nav-section-title">
                        <span>{{ section.title }}</span>
                    </h3>
                    <div class="landing-nav-section-content">
                        <button
                          type="button"
                          id="landing-nav-overview"
                          class="landing-nav-btn mod-active"
                          v-for="(item, itemIndex) in section.items"
                          :key="itemIndex"
                          v-on:click="open(item.url)"
                        >
                            <span class="landing-nav-btn-inner">
                                <span class="landing-nav-btn-text">{{ item.title }}</span>
                                <span class="landing-nav-btn-progress"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'LandingNav',
  data() {
    return {
      sections: [
        {
          title: 'Projects',
          items: [
            {
              title: 'Vue Img Enhanced',
              url: 'https://www.npmjs.com/package/vue-img-enhanced',
            },
            {
              title: 'Adonis Drive Azure Storage',
              url: 'https://github.com/AlexanderYW/Adonis-Drive-Azure-Storage',
            },
            {
              title: 'Fluentify',
              url: 'https://github.com/AlexanderYW/Adonis-Drive-Azure-Storage',
            },
          ],
        },
        {
          title: 'Portfolio',
          items: [
            {
              title: 'points.flysas.com',
              url: 'https://points.flysas.com?ref=alexandermedia.dk',
            },
            {
              title: 'minhemmelighed.dk',
              url: 'https://minhemmelighed.dk?ref=alexandermedia.dk',
            },
          ],
        },
        {
          title: 'Contributions',
          items: [
            {
              title: 'Vue-Dropzone',
              url: 'https://github.com/rowanwins/vue-dropzone',
            },
            {
              title: 'Azurite',
              url: 'https://github.com/Azure/Azurite',
            },
          ],
        },
      ],
    };
  },
  methods: {
    open(path) {
      window.open(path, '_blank');
    },
  },
};
</script>
