<template>
  <div class="full-height">
    <div class="container" :class="showLogo ? 'view': ''">
      <header>
        <logo />
      </header>
      <br />
      <br />
      <h1>
        Full-stack developer at <a :href="workLink" target="_blank" rel="external nofollow">{{ workName }}</a>
      </h1>
      <nav>
        <ul>
          <li><a href="https://github.com/AlexanderYW" rel="external">Github</a></li>
          <li><a href="https://gitlab.com/alexanderyw" rel="external">Gitlab</a></li>
          <li><a href="https://twitter.com/alexanderyw" rel="external">Twitter</a></li>
          <li><a href="https://www.behance.net/awenners" rel="external">Behance</a></li>
          <li><a href="mailto:alexanderw0310@gmail.com" rel="external">Email</a></li>
        </ul>
      </nav>
    </div>
    <landing-nav />
  </div>
</template>

<script>
import Logo from './components/Logo';
import LandingNav from './components/LandingNav';

export default {
  data() {
    return {
      showLogo: false,
      name: 'Alexander Wennerstrøm',
      workName: 'Tryg A/S',
      workLink: 'https://tryg.dk/',
      age: new Date().getFullYear() - new Date('10/03/1993').getFullYear() - 1,
      description() {
        return `Mit navn er ${this.name}, jeg er ${this.age} år gammel, og er uddannet som mediegrafiker – Jeg arbejder til dagligt som front-end developer hos ${this.workName}`;
      },
      keywords: ['web developer', 'developer', 'webdesigner', 'udvikler', 'freelance', 'front-end', 'frontend', 'nodejs', 'javascript', 'vue', 'vuejs', 'designer', 'mediegrafiker', 'open source', 'npm'],
    };
  },
  head: {
    title() {
      return {
        inner: `${this.name}`,
      };
    },
    // Meta tags
    meta() {
      return [
        { name: 'application-name', content: 'AlexanderMedia' },
        {
          n: 'description',
          c: this.description(),
        },
        {
          name: 'keywords',
          content: this.keywords.join(','),
        },
        {
          name: 'author',
          content: this.name,
        },
        // ...
        // Twitter
        { name: 'twitter:title', content: `${this.name} - Frontend developer` },
        // with shorthand
        { n: 'twitter:description', c: this.description() },
        // ...
        // Google+ / Schema.org
        { itemprop: 'name', content: `${this.name}` },
        { itemprop: 'description', content: this.description() },
        // ...
        // Facebook / Open Graph
        // { property: 'fb:app_id', content: '123456789' },
        { property: 'og:title', content: `${this.name} - Frontend developer` },
      ];
    },
  },
  name: 'Home',
  mounted() {
    this.$set(this, 'showLogo', true);
  },
  components: {
    Logo,
    LandingNav,
  },
};
</script>
