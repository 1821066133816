// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueGtag from "vue-gtag";
import Raven from 'raven-js';
import VueHead from 'vue-head';
import RavenVue from 'raven-js/plugins/vue';
import App from './App';
import router from './router';
import './assets/scss/style.scss';

Vue.use(VueHead, {
  complement: 'Frontend Developer',
});

Raven
  .config('https://2e1a026133f24f50885161ed50fda4ee@sentry.io/1213870')
  .addPlugin(RavenVue, Vue)
  .install();

Vue.use(VueGtag, {
  appName: 'Portfolio',
  config: {
    id: "UA-1234567-1"
  },
  pageTrackerScreenviewEnabled: true
}, router);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
